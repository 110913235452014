/* eslint-disable @typescript-eslint/no-explicit-any */
import { getUserStorageType, getUserToken } from "./global";
import { removeStorage, setStorage } from "./storage";

/**
 * Get the user session object from AsyncStorage
 * UserId, token, refreshToken
 */
export const getUserSession = () => {
    try {
      const webToken = getUserToken();
      return webToken
    } catch {
      return null
    }
  }
  
  export const setUserSession = (tokens: any) => {
    if (tokens) {
      setStorage(`${getUserStorageType()}token`, tokens.token)
      setStorage(`${getUserStorageType()}refreshToken`, tokens.refreshToken);
    } else {
      removeStorage(`${getUserStorageType()}token`)
      removeStorage(`${getUserStorageType()}refreshToken`);
    }
  }