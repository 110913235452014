
export const defaultStyles = {
    headerBackgroundColor: '#FFFFFF',
    headerBusinessTextColor: '#212529',
    headerTextColor: '#758AF0',
    chatBackgroundColor: '#F5F5F5',
    chatTitleColor: '#334E67',
    chatSubTitleColor: '#334E67',
    suggestedQuestionTextColor: '#758AF0',
    suggestedQuestionBackgroundColor: '#FFFFFF',
    suggestedQuestionBorderColor: '#758AF0',
    chatQuestionTextColor: '#334E67',
    chatAnswerTextColor: '#334E67',
    chatQuestionBackgroundColor: '#D9F2E5',
    chatAnswerBackgroundColor: '#FFFFFF'
}
