const VS_ORIGIN_URL = window.location.origin

export const logoImgPath = VS_ORIGIN_URL + '/images/logo.png';

export const homeImgPath = VS_ORIGIN_URL + '/images/home.png';

export const avatarImgPath = VS_ORIGIN_URL + '/images/avatar.png';

export const directoryImgPath = VS_ORIGIN_URL + '/images/directory.png';

export const moreImgPath = VS_ORIGIN_URL + '/images/More.svg';

export const micImgPath = VS_ORIGIN_URL + '/images/mic.svg';

export const profileImgPath = VS_ORIGIN_URL + '/images/user_profile_pics/user.png';

export const searchImgPath  = VS_ORIGIN_URL + '/images/Search.png';

export const documentsImgPath  = VS_ORIGIN_URL + '/images/documents.png';

export const addImgPath = VS_ORIGIN_URL + '/images/Button/Icon/Add.png';

export const deleteImgPath = VS_ORIGIN_URL + '/images/Delete.png';

export const vectorImgPath = VS_ORIGIN_URL + '/images/Icon/Vector.png';

export const pdfImgPath = VS_ORIGIN_URL + '/images/pdf.svg';

export const CopyIcon = VS_ORIGIN_URL + '/images/Icon/Copy.svg';

export const ShareIcon = VS_ORIGIN_URL + '/images/Icon/Share.svg';

export const micActiveImgPath = VS_ORIGIN_URL + '/images/mic-active.svg';

export const waveImgPath = VS_ORIGIN_URL + '/images/wave.svg';

export const playImgPath = VS_ORIGIN_URL + '/images/play.svg';

export const stopImgPath = VS_ORIGIN_URL + '/images/stop.svg';

export const userImgPath = VS_ORIGIN_URL + '/images/Users.svg';

export const likeImgPath = VS_ORIGIN_URL + '/images/Helpful.svg';

export const likeSmallImgPath = VS_ORIGIN_URL + '/images/HelpfulSmall.svg';

export const noteSmallImgPath = VS_ORIGIN_URL + '/images/AddNoteSmall.svg';

export const headerChatIcon = VS_ORIGIN_URL + '/images/Header-Chat.svg';

export const manageIcon = VS_ORIGIN_URL + '/images/Manage.svg';

export const tabUsersIcon = VS_ORIGIN_URL + 'images/TabUsers.svg';

export const noteWhiteImgPath = VS_ORIGIN_URL + '/images/NoteWhite.svg';

export const helpfulWhiteImgPath =  VS_ORIGIN_URL + '/images/HelpfulWhite.svg';

export const chatTextImgPath =  VS_ORIGIN_URL + '/images/chat-text.svg';

export const urlCopyImgPath =  VS_ORIGIN_URL + '/images/URL-Copy.svg';

export const poweredByVoiceSphereImgPath =  VS_ORIGIN_URL + '/images/powered_by_voicesphere.png';
