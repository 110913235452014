import React from "react";
import { motion } from "framer-motion";

const loadingContainer = {
  width: "4rem",
  height: "4rem",
  display: "flex",
  justifyContent: "space-around",
};

const loadingContainerVariants = {
  start: {
    transition: {
      staggerChildren: 0.2,
    },
  },
  end: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const loadingCircleVariants = {
  start: {
    y: "0%",
  },
  end: {
    y: "60%",
  },
};
const loadingCircleTransition = {
  duration: 0.4,
  yoyo: Infinity,
  ease: "easeInOut",
};

const Loader = () => {
  return (
    <div style={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      position:'absolute',
      bottom:'20%',
      left:'45%'
    }}>
      <motion.div
        style={loadingContainer}
        variants={loadingContainerVariants}
        initial="start"
        animate="end">
        <motion.span
          style={{
            display: "block",
            width: "1rem",
            height: "1rem",
            backgroundColor: "#758AF0",
            borderRadius: "0.5rem",
          }}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        ></motion.span>
        <motion.span
          style={{
            display: "block",
            width: "1rem",
            height: "1rem",
            backgroundColor: "#61CA96",
            borderRadius: "0.5rem",
          }}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        ></motion.span>
        <motion.span
          style={{
            display: "block",
            width: "1rem",
            height: "1rem",
            backgroundColor: "#8086FF",
            borderRadius: "0.5rem",
          }}
          variants={loadingCircleVariants}
          transition={loadingCircleTransition}
        ></motion.span>
      </motion.div>
    </div>
  );
};

export default Loader;
