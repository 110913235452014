/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from 'jwt-decode'

export const isJwtExpired = (token: any) => {
  if (typeof(token) !== 'string' || !token) throw new Error('Invalid token provided');

  let isJwtExpired = false;
  const { exp } = jwtDecode(token) as any;
  const currentTime = new Date().getTime() / 1000;

  if (currentTime > exp) isJwtExpired = true;

  return isJwtExpired;
}
