/* eslint-disable @typescript-eslint/no-explicit-any */

import io from "socket.io-client";
import { VS_PORTAL_MESSAGE_SOCKET_URL } from "./constants";
import { getUserToken } from "./global";

const token: any = getUserToken();

const socketToken: any = token ? token.replace('Bearer ', '') : '';

const socket = io(`${VS_PORTAL_MESSAGE_SOCKET_URL}`, {
    auth: {
        token: socketToken,
        origin: window.location.origin,
    },
    transports: ["websocket"],
    forceNew: false,
});

export default socket