/* eslint-disable @typescript-eslint/no-explicit-any */
import { refreshTokenPerform } from '../services/refreshTokenPerform';
import { getUserToken } from './global';
import { isJwtExpired } from './jwt';
import { setStorage } from './storage';
import { getUserSession } from './storageManager';

const useUserInformation = () => {

  const userSession = getUserToken();
  const verifyReduxToken = async () => {
    // check and change only if the Token is expired
    const token = getUserSession()
    if (token && isJwtExpired(token)) {
      const tokenResponse: any = await refreshTokenPerform();
      console.log('newToken')
      if (tokenResponse.webToken) {
        setStorage('token', tokenResponse.webToken);
        setStorage('refreshToken', tokenResponse.webRefreshToken);
        window.location.reload()
      }
      return tokenResponse.webToken
    }
    return token
  }

  const autoLogin = async (routeBusinessId: any, routeCollectionId: any, rediectionUrl: any) => {
    const tokenResponse: any = await refreshTokenPerform(true)
    if (tokenResponse?.webToken) {
      setStorage('token', tokenResponse.webToken);
      setStorage('refreshToken', tokenResponse.webRefreshToken);
      setStorage('userId', tokenResponse.loggedUserId);      
      if (routeBusinessId && routeCollectionId) {
        setStorage('routeCollectionId', routeCollectionId);
        setStorage('routeBusinessId', routeBusinessId);
        window.location.href = rediectionUrl;
      } else {
        window.location.reload()
      }
    }
  }

  return {
    userSession,
    verifyReduxToken,
    autoLogin
  }
}

export default useUserInformation
