/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes
} from "react-router-dom";
import "./css/responsive.css";
import "./css/style.css";
import { getUserStorageType, getUserToken } from "./utils/global";
import { isJwtExpired } from "./utils/jwt";
import { setStorage } from "./utils/storage";
import useUserInformation from "./utils/useUserSession";
import IframeChat from "./pages/IframeChat";
import NotFound from "./pages/NotFound";
const auth = getUserToken();

function App() {
  const { userSession, verifyReduxToken, autoLogin } = useUserInformation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoggedIn, setIsLoggedIn] = useState(userSession as any);
  const queryParams = new URLSearchParams(window.location.search);
  const refreshToken = queryParams.get("rt");
  const redirectionUrl = window.location.href.split('/?rt=')?.[0];

  useEffect(() => {
    if (userSession) {
      if (isJwtExpired(userSession)) {
        verifyReduxToken();
      }
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [userSession]);

  useEffect(() => {
    if (refreshToken && !getUserToken()) {
      const pathSplit = window.location.pathname.split('/');
      const routeBusinessId = pathSplit?.[2];
      const routeCollectionId = pathSplit?.[3];
      setStorage(`${getUserStorageType()}refreshToken`, refreshToken);
      autoLogin(routeBusinessId, routeCollectionId, redirectionUrl);
      setIsLoggedIn(true);
    }
  }, [refreshToken]);

  if (userSession && isJwtExpired(userSession)) {
    return null;
  }
  

  
  return (
    <Router>
      <Routes>       
        <Route path={`/embed_doc/:collectionId`} element={<IframeChat />} />
        {auth && <Route path={`/embed_doc/:collectionId`} element={<IframeChat />} />}       
        {auth && <Route path={`*`} element={<NotFound />} />}
        </Routes>
    </Router>
  )
}

export default App;
